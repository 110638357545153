import { FormProvider } from 'react-hook-form';
import { ReactNode } from 'react';

export type FormProps = {
  children: ReactNode;
  form: any;
  handleSubmit: any;
};

export const Form = ({ children, form, handleSubmit }: FormProps) => {
  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <div>{children}</div>
        </form>
      </FormProvider>
    </>
  );
};
