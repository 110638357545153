import { useIntl } from 'react-intl';
import { WelcomeMessage } from './components/welcome-message/WelcomeMessage';
import { UpcomingOrders } from './components/upcoming-orders/UpcomingOrders';
import { SubscripstionsList } from './components/subscriptions-list/SubscriptionsList';
import { useSelector } from 'react-redux';
import { RecipeOverview } from './components/recipe-overview/RecipeOverview';
import { NewDog } from './components/new-dog/NewDog';
import { Section } from '@layout/components';
import { getPetSelector, getSubscriptionsSelector } from '@modules/subscriptions/core/selectors';
import { getCurrentOrderSelector } from '@modules/order-schedule/core/selectors';

export const DashboardPage = () => {
  const intl = useIntl();
  const subscriptions = useSelector(getSubscriptionsSelector);
  const pet = useSelector(getPetSelector);
  const order = useSelector(getCurrentOrderSelector);

  return (
    <>
      <Section>
        <>
          <div className="flex flex-col-reverse md:flex-row lg:mb-[3rem]">
            <div className="w-full md:w-1/2">
              <h1 className="text-[2.2rem] text-center md:text-left font-medium text-primary font-Aniuk mb-[1rem]">
                Willkommen in deinem Kundenportal{pet?.name ? `, ${pet?.name}` : null}!
              </h1>
            </div>
            {subscriptions?.length ? (
              <div className="flex w-full justify-end md:w-1/2 mb-[2rem] md:mb-0">
                <SubscripstionsList />
              </div>
            ) : null}
          </div>
        </>

        <div className="flex flex-col space-y-6 ">
          <div className="flex flex-col space-y-6 md:flex-row md:space-x-6 md:space-y-0">
            <div className="flex w-full md:w-1/2">
              <WelcomeMessage />
            </div>
            <div className="flex w-full md:w-1/2">
              <NewDog />
            </div>
          </div>

          {subscriptions?.length ? (
            <div className="flex flex-col space-y-6 md:flex-row md:space-x-6 md:space-y-0">
              <div className="flex flex-1 w-full md:w-1/2">
                <UpcomingOrders order={order} />
              </div>
              <div className="flex flex-1 w-full md:w-1/2">
                <RecipeOverview />
              </div>
            </div>
          ) : null}
        </div>
      </Section>
    </>
  );
};
