import { ReactNode } from 'react';
import { Divider } from '../../../../src/shared/layout/components/Divider';

type WidgetProps = {
  header: ReactNode;
  footer?: ReactNode;
  children: ReactNode;
};

// TODO: IMPROVE
export const Widget = ({ header, footer, children }: WidgetProps) => {
  return (
    <div className="bg-light-100 rounded-[3rem] w-full flex flex-col h-full justify-between">
      <div className="widget-header">{header}</div>
      <div className="flex-grow widget-content">{children}</div>
      {footer && (
        <>
          <div className="widget-footer">
            <Divider />
            <div className="widget-footer-inner">{footer}</div>
          </div>
        </>
      )}
    </div>
  );
};
