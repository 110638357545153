import {InputHTMLAttributes, ReactNode, useEffect} from 'react'
import {RegisterOptions, useFormContext} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'

type InputProps = {
  name: string
  hints?: ReactNode
  label?: string
  validationOptions?: RegisterOptions
} & InputHTMLAttributes<HTMLInputElement>

export const Input = ({
  name,
  disabled,
  label,

  validationOptions,

  value,
  ...rest
}: InputProps) => {
  const {
    register,
    formState: {errors},
    setFocus,
  } = useFormContext()

  useEffect(() => {
    if (rest.autoFocus) {
      setFocus(name)
    }
  }, [rest.autoFocus])

  return (
    <>
      {Boolean(label) && <label htmlFor={name}>{label}</label>}
      <div>
        <input
          {...register(name, validationOptions)}
          disabled={disabled}
          value={value}
          {...rest}
          name={name}
        />
      </div>
      <ErrorMessage
        name={name}
        errors={errors}
        as={<p className='font-medium ml-[.5rem] required-input' />}
      />
    </>
  )
}
