import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchAuthViaSessionRequest } from '../core/actions';

export const LoginViaSession = () => {
  const dispatch = useDispatch();

  const location = useLocation();

  // Function to parse query parameters
  const getQueryParam = (param: any) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(param);
  };

  // Extract sessionToken from URL
  const sessionToken = getQueryParam('sessionToken');

  useEffect(() => {
    // Check if sessionToken is available
    if (sessionToken) {
      // Dispatch the fetchAuthRequest action with sessionToken
      dispatch(fetchAuthViaSessionRequest({ sessionToken }));
    }
  }, [sessionToken, dispatch]);

  return (
    <div className="flex flex-col items-center justify-center h-full login">
      <h1 className="text-center text-primary ">
        WILKOMMEN IN DEINEM
        <br />
        <span>KUNDENPORTAL</span>
      </h1>
    </div>
  );
};
