import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { NAVI, MenuItem } from '@constants/navi';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';
import { useLayout } from '@layout/core';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { getPetSelector } from '@modules/subscriptions/core/selectors';

export function HeaderWrapper() {
  const location = useLocation();
  const intl = useIntl();

  const { config, classes, attributes } = useLayout();
  const { aside } = config;

  const pet = useSelector(getPetSelector);

  const activeMenuItem = NAVI.find((menuItem: MenuItem) => menuItem.to === location.pathname);
  const activeMenuTitleValues = activeMenuItem?.pet ? { petName: pet?.name } : null;

  return (
    <div
      id="kt_header"
      className={clsx('header', classes.header.join(' '), 'items-stretch')}
      {...attributes.headerMenu}
    >
      <div className="container flex items-center justify-between mx-auto">
        <div className="flex lg:hidden">
          <Link to="/dashboard">
            <SVG
              src={toAbsoluteUrl('/media/logos/logo.svg')}
              className="w-[5rem] mx-[1rem] h-fit py-[.5rem]"
            />
          </Link>
        </div>

        {activeMenuItem && (
          <div className="flex items-stretch justify-end lg:grow">
            <div className="items-stretch flex-shrink-0 text-4xl font-extrabold uppercase text-primary">
              {intl.formatMessage({ id: activeMenuItem?.title }, { ...activeMenuTitleValues })}
            </div>
          </div>
        )}

        {aside.display && (
          <div className="flex items-center lg:hidden" title="Show aside menu">
            <div
              className="btn btn-icon btn-active-light-primary p-[9px] btn-custom w-30px h-30px w-md-40px h-md-40px"
              id="kt_aside_mobile_toggle"
            >
              <KTSVG path="/media/svg/header/hamburger-menu.svg" className="svg-icon-2x" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
