import { useDispatch } from 'react-redux';
import { SubmitHandler, useController, useForm, UseFormReturn } from 'react-hook-form';
import { updateCustomerRequest, ICustomer } from '@modules/customer';

export const useCustomerForm = (customer: ICustomer) => {
  const dispatch = useDispatch();

  const form: UseFormReturn<any> = useForm<any>({
    defaultValues: {
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
    },
  });

  const onSubmit: SubmitHandler<any> = async ({ firstName, lastName, email }: any) => {
    try {
      dispatch(
        updateCustomerRequest({
          id: customer?.id!,
          params: {
            first_name: firstName,
            last_name: lastName,
          },
        }),
      );
    } catch (error) {
      console.log('Error occured while updating customer', error);
    }
  };

  const firstNameController = useController({
    name: 'firstName',
    control: form.control,
    defaultValue: '',
  });

  const lastNameController = useController({
    name: 'lastName',
    control: form.control,
    defaultValue: '',
  });

  const emailController = useController({
    name: 'email',
    control: form.control,
    defaultValue: '',
  });

  return {
    form,

    onSubmit,

    firstNameController,
    lastNameController,
    emailController,
  };
};
