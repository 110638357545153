import axios, { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { fetchProductsFailure, fetchProductsSuccess } from './actions';
import { FETCH_PRODUCTS_REQUEST } from './actionTypes';
import { IProduct, ProductParams } from './types';

const getProducts = (params: ProductParams) => {
  return axios.get<IProduct[]>(`/api/v1/products/${params.product_id}`, { params });
};

function* fetchProductsSaga(action: any) {
  try {
    const response: AxiosResponse<IProduct[]> = yield call(() => getProducts(action.params));
    yield put(
      fetchProductsSuccess({
        products: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchProductsFailure({
        error: e.message,
      }),
    );
  }
}

export function* productsSaga() {
  yield all([takeLatest(FETCH_PRODUCTS_REQUEST, fetchProductsSaga)]);
}
