import React, { useState } from 'react';

type DatePickerProps = {
  label?: string;
};

export const DatePicker = ({ label }: DatePickerProps) => {
  const [date, setDate] = useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };

  return (
    <div className="p-[4rem]">
      {label && (
        <label htmlFor="datepicker" className="block font-bold text-primary mb-[2rem]">
          {label}
        </label>
      )}
      <input
        type="date"
        id="datepicker"
        value={date || ''}
        onChange={handleChange}
        className="block w-full p-[2rem] mb-[6rem] text-primary bg-white border-[3px] rounded-[2rem] border-primary cursor-pointer"
      />
    </div>
  );
};
