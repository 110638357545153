import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { MetronicI18nProvider } from './shared/i18n/Metronici18n';
import './shared/assets/sass/main.scss';

import { persistor, store } from './store/store';

import { AppRoutes } from './app/routing/AppRoutes';
import { ThemeModeProvider } from './shared/partials/layout/theme-mode/ThemeModeProvider';
import { PersistGate } from 'redux-persist/integration/react';
import setupAxios from './app/utils/setupAxios';

const container = document.getElementById('root');

setupAxios(store);

if (container) {
  createRoot(container).render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MetronicI18nProvider>
            <ThemeModeProvider>
              <AppRoutes />
            </ThemeModeProvider>
          </MetronicI18nProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
  );
}
