import { lazy, FC, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../shared/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { getCSSVariableValue } from '../../shared/assets/ts/_utils';
import { WithChildren } from '../../shared/helpers';

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));

  const OrdersPage = lazy(() => import('../modules/orders/OrdersPage'));
  const SubscriptionPage = lazy(() => import('../modules/subscriptions/SubscriptionPage'));

  const DummyPage = lazy(() => import('../modules/dummy/DummyPage'));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path="/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path="/orders"
          element={
            <SuspensedView>
              <OrdersPage />
            </SuspensedView>
          }
        />

        <Route
          path="/subscriptions"
          element={
            <SuspensedView>
              <SubscriptionPage />
            </SuspensedView>
          }
        />

        <Route
          path="/dummy"
          element={
            <SuspensedView>
              <DummyPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
