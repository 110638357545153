import { getCustomerSelector } from '@modules/customer';
import { KTSVG, toAbsoluteUrl } from '../../../../../shared/helpers/index';
import { useSelector } from 'react-redux';
import { getPetSelector } from '@modules/subscriptions/core/selectors';

export const WelcomeMessage = () => {
  const pet = useSelector(getPetSelector);
  const customer = useSelector(getCustomerSelector);

  return (
    <div className="flex pt-[10rem] w-full relative welcome-message">
      <div>
        <img
          src={toAbsoluteUrl('/media/img/misc/welcome.png')}
          alt="welcome"
          className="w-auto h-100"
        />
      </div>
      <div className="welcome-message-cloud">
        <div className="cloud-pointer">
          <KTSVG path={toAbsoluteUrl('/media/svg/misc/cloud-pointer.svg')} />
        </div>

        {pet?.name && <p>Hallo {pet?.name}!</p>}
        <p>Hallo {customer?.firstName}!</p>
      </div>
    </div>
  );
};
