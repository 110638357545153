export const FETCH_AUTH_REQUEST = 'FETCH_AUTH_REQUEST';
export const FETCH_AUTH_SUCCESS = 'FETCH_AUTH_SUCCESS';
export const FETCH_AUTH_FAILURE = 'FETCH_AUTH_FAILURE';

export const FETCH_AUTH_VIA_SESSION_REQUEST = 'FETCH_AUTH_VIA_SESSION_REQUEST';
export const FETCH_AUTH_VIA_SESSION_SUCCESS = 'FETCH_AUTH_VIA_SESSION_SUCCESS';
export const FETCH_AUTH_VIA_SESSION_FAILURE = 'FETCH_AUTH_VIA_SESSION_FAILURE';

export const FETCH_REAUTH_REQUEST = 'FETCH_REAUTH_REQUEST';
export const FETCH_REAUTH_SUCCESS = 'FETCH_REAUTH_SUCCESS';
export const FETCH_REAUTH_FAILURE = 'FETCH_REAUTH_FAILURE';

export const FETCH_AUTH_LOGOUT_REQUEST = 'FETCH_AUTH_LOGOUT_REQUEST';
