import axios, { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  fetchAuthRequest,
  fetchAuthFailure,
  fetchAuthSuccess,
  fetchReAuthRequest,
  fetchReAuthSuccess,
  fetchReAuthFailure,
  fetchAuthViaSessionRequest,
  fetchAuthViaSessionSuccess,
  fetchAuthViaSessionFailure,
} from './actions';
import {
  FETCH_AUTH_REQUEST,
  FETCH_AUTH_VIA_SESSION_REQUEST,
  FETCH_REAUTH_REQUEST,
} from './actionTypes';
import {
  FetchAuthRequestPayload,
  FetchAuthViaSessionRequestPayload,
  FetchReAuthRequestPayload,
} from './types';

const login = async (email: string, password: string) => {
  const params: FetchAuthRequestPayload = {
    email,
    password,
  };

  const response = await axios.post<any>(`/api/v1/login`, params);

  return response.data;
};

const loginViaSession = async (sessionToken: string) => {
  const params: FetchAuthViaSessionRequestPayload = {
    sessionToken,
  };

  const response = await axios.post<any>(`/api/v1/auth`, params);

  return response.data;
};

const relogin = async (subscriptionId: number, addressId: number) => {
  const params: { subscription: FetchReAuthRequestPayload } = {
    subscription: { id: subscriptionId, address_id: addressId },
  };

  const response = await axios.post<any>(`/api/v1/relogin`, params);

  return response.data;
};

function* fetchAuthSaga(action: ReturnType<typeof fetchAuthRequest>) {
  try {
    const response: AxiosResponse<any> = yield call(() =>
      login(action.payload.email, action.payload.password),
    );

    yield put(
      fetchAuthSuccess({
        auth: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchAuthFailure({
        error: e.message,
      }),
    );
  }
}

function* fetchAuthViaSessionSaga(action: ReturnType<typeof fetchAuthViaSessionRequest>) {
  try {
    const response: AxiosResponse<any> = yield call(() =>
      loginViaSession(action.payload.sessionToken),
    );

    yield put(
      fetchAuthViaSessionSuccess({
        auth: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchAuthViaSessionFailure({
        error: e.message,
      }),
    );
  }
}

function* fetchReAuthSaga(action: ReturnType<typeof fetchReAuthRequest>) {
  try {
    const response: AxiosResponse<any> = yield call(() =>
      relogin(action.payload.id, action.payload.address_id),
    );

    yield put(
      fetchReAuthSuccess({
        auth: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchReAuthFailure({
        error: e.message,
      }),
    );
  }
}

export function* authSaga() {
  yield all([
    takeLatest(FETCH_AUTH_REQUEST, fetchAuthSaga),
    takeLatest(FETCH_AUTH_VIA_SESSION_REQUEST, fetchAuthViaSessionSaga),
    takeLatest(FETCH_REAUTH_REQUEST, fetchReAuthSaga),
  ]);
}
