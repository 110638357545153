import axios, { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  fetchOrderScheduleFailure,
  fetchOrderScheduleRequest,
  fetchOrderScheduleSuccess,
} from './actions';
import { FETCH_ORDER_SCHEDULE_REQUEST } from './actionTypes';
import { FetchOrderScheduleRequestPayload, IOrderSchedule } from './types';

const getOrderSchedule = async (subscriptionId: number) => {
  const params: FetchOrderScheduleRequestPayload = {
    subscriptionId,
  };

  return await axios.get<any>(`/api/v1/subscriptions/${subscriptionId}/order_schedule`, { params });
};

function* fetchOrderScheduleSaga(action: ReturnType<typeof fetchOrderScheduleRequest>) {
  try {
    const response: AxiosResponse<any> = yield call(() =>
      getOrderSchedule(action.payload.subscriptionId),
    );

    yield put(
      fetchOrderScheduleSuccess({
        orderSchedule: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchOrderScheduleFailure({
        error: e.message,
      }),
    );
  }
}

export function* orderScheduleSaga() {
  yield all([takeLatest(FETCH_ORDER_SCHEDULE_REQUEST, fetchOrderScheduleSaga)]);
}
