export const FETCH_ORDER_HISTORY_REQUEST = 'FETCH_ORDER_HISTORY_REQUEST';
export const FETCH_ORDER_HISTORY_SUCCESS = 'FETCH_ORDER_HISTORY_SUCCESS';
export const FETCH_ORDER_HISTORY_FAILURE = 'FETCH_ORDER_HISTORY_FAILURE';

export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';

export const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';

export const SKIP_ORDER_REQUEST = 'SKIP_ORDER_REQUEST';
export const SKIP_ORDER_SUCCESS = 'SKIP_ORDER_SUCCESS';
export const SKIP_ORDER_FAILURE = 'SKIP_ORDER_FAILURE';

export const UNSKIP_ORDER_REQUEST = 'UNSKIP_ORDER_REQUEST';
export const UNSKIP_ORDER_SUCCESS = 'UNSKIP_ORDER_SUCCESS';
export const UNSKIP_ORDER_FAILURE = 'UNSKIP_ORDER_FAILURE';
