import {
  FETCH_SUBSCRIPTIONS_REQUEST,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_FAILURE,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  FETCH_CANCELLATION_FLOW_REQUEST,
  FETCH_CANCELLATION_FLOW_SUCCESS,
  FETCH_CANCELLATION_FLOW_FAILURE,
  PAUSE_SUBSCRIPTION_REQUEST,
  PAUSE_SUBSCRIPTION_SUCCESS,
  PAUSE_SUBSCRIPTION_FAILURE,
  RESUME_SUBSCRIPTION_REQUEST,
  RESUME_SUBSCRIPTION_SUCCESS,
  RESUME_SUBSCRIPTION_FAILURE,
  REACTIVATE_SUBSCRIPTION_REQUEST,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
  REACTIVATE_SUBSCRIPTION_FAILURE,
  UPDATE_SUBSCRIPTION_ADDRESS_REQUEST,
  UPDATE_SUBSCRIPTION_ADDRESS_SUCCESS,
  UPDATE_SUBSCRIPTION_ADDRESS_FAILURE,
  UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_REQUEST,
  UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_SUCCESS,
  UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_FAILURE,
} from './actionTypes';
import {
  FetchSubscriptionsRequest,
  FetchSubscriptionsSuccess,
  FetchSubscriptionsSuccessPayload,
  FetchSubscriptionsFailure,
  FetchSubscriptionsFailurePayload,
  UpdateSubscriptionAddressRequestPayload,
  UpdateSubscriptionAddressRequest,
  UpdateSubscriptionAddressSuccessPayload,
  UpdateSubscriptionAddressSuccess,
  UpdateSubscriptionAddressFailurePayload,
  UpdateSubscriptionAddressFailure,
  CancelSubscriptionRequest,
  CancelSubscriptionRequestPayload,
  CancelSubscriptionSuccess,
  CancelSubscriptionSuccessPayload,
  CancelSubscriptionFailure,
  CancelSubscriptionFailurePayload,
  FetchCancellationFlowRequest,
  FetchCancellationFlowRequestPayload,
  FetchCancellationFlowSuccess,
  FetchCancellationFlowSuccessPayload,
  FetchCancellationFlowFailure,
  FetchCancellationFlowFailurePayload,
  ResumeSubscriptionRequest,
  ResumeSubscriptionRequestPayload,
  ResumeSubscriptionSuccess,
  ResumeSubscriptionSuccessPayload,
  ResumeSubscriptionFailure,
  ResumeSubscriptionFailurePayload,
  PauseSubscriptionRequest,
  PauseSubscriptionRequestPayload,
  PauseSubscriptionSuccess,
  PauseSubscriptionSuccessPayload,
  PauseSubscriptionFailure,
  PauseSubscriptionFailurePayload,
  ReactivateSubscriptionRequest,
  ReactivateSubscriptionRequestPayload,
  ReactivateSubscriptionSuccess,
  ReactivateSubscriptionSuccessPayload,
  ReactivateSubscriptionFailure,
  ReactivateSubscriptionFailurePayload,
  UpdateSubscriptionNextChargeDateSuccessPayload,
  UpdateSubscriptionNextChargeDateSuccess,
  UpdateSubscriptionNextChargeDateFailurePayload,
  UpdateSubscriptionNextChargeDateFailure,
  UpdateSubscriptionNextChargeDateRequestPayload,
  UpdateSubscriptionNextChargeDateRequest,
} from './types';

export const fetchSubscriptionsRequest = (): FetchSubscriptionsRequest => ({
  type: FETCH_SUBSCRIPTIONS_REQUEST,
});

export const fetchSubscriptionsSuccess = (
  payload: FetchSubscriptionsSuccessPayload,
): FetchSubscriptionsSuccess => ({
  type: FETCH_SUBSCRIPTIONS_SUCCESS,
  payload,
});

export const fetchSubscriptionsFailure = (
  payload: FetchSubscriptionsFailurePayload,
): FetchSubscriptionsFailure => ({
  type: FETCH_SUBSCRIPTIONS_FAILURE,
  payload,
});

export const updateSubscriptionAddressRequest = (
  payload: UpdateSubscriptionAddressRequestPayload,
): UpdateSubscriptionAddressRequest => ({
  type: UPDATE_SUBSCRIPTION_ADDRESS_REQUEST,
  payload,
});

export const updateSubscriptionAddressSuccess = (
  payload: UpdateSubscriptionAddressSuccessPayload,
): UpdateSubscriptionAddressSuccess => ({
  type: UPDATE_SUBSCRIPTION_ADDRESS_SUCCESS,
  payload,
});

export const updateSubscriptionAddressFailure = (
  payload: UpdateSubscriptionAddressFailurePayload,
): UpdateSubscriptionAddressFailure => ({
  type: UPDATE_SUBSCRIPTION_ADDRESS_FAILURE,
  payload,
});

export const cancelSubscriptionRequest = (
  payload: CancelSubscriptionRequestPayload,
): CancelSubscriptionRequest => ({
  type: CANCEL_SUBSCRIPTION_REQUEST,
  payload,
});

export const cancelSubscriptionSuccess = (
  payload: CancelSubscriptionSuccessPayload,
): CancelSubscriptionSuccess => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
  payload,
});

export const cancelSubscriptionFailure = (
  payload: CancelSubscriptionFailurePayload,
): CancelSubscriptionFailure => ({
  type: CANCEL_SUBSCRIPTION_FAILURE,
  payload,
});

export const fetchCancellationFlowRequest = (
  payload: FetchCancellationFlowRequestPayload,
): FetchCancellationFlowRequest => ({
  type: FETCH_CANCELLATION_FLOW_REQUEST,
  payload,
});

export const fetchCancellationFlowSuccess = (
  payload: FetchCancellationFlowSuccessPayload,
): FetchCancellationFlowSuccess => ({
  type: FETCH_CANCELLATION_FLOW_SUCCESS,
  payload,
});

export const fetchCancellationFlowFailure = (
  payload: FetchCancellationFlowFailurePayload,
): FetchCancellationFlowFailure => ({
  type: FETCH_CANCELLATION_FLOW_FAILURE,
  payload,
});

export const pauseSubscriptionRequest = (
  payload: PauseSubscriptionRequestPayload,
): PauseSubscriptionRequest => ({
  type: PAUSE_SUBSCRIPTION_REQUEST,
  payload,
});

export const pauseSubscriptionSuccess = (
  payload: PauseSubscriptionSuccessPayload,
): PauseSubscriptionSuccess => ({
  type: PAUSE_SUBSCRIPTION_SUCCESS,
  payload,
});

export const pauseSubscriptionFailure = (
  payload: PauseSubscriptionFailurePayload,
): PauseSubscriptionFailure => ({
  type: PAUSE_SUBSCRIPTION_FAILURE,
  payload,
});

export const resumeSubscriptionRequest = (
  payload: ResumeSubscriptionRequestPayload,
): ResumeSubscriptionRequest => ({
  type: RESUME_SUBSCRIPTION_REQUEST,
  payload,
});

export const resumeSubscriptionSuccess = (
  payload: ResumeSubscriptionSuccessPayload,
): ResumeSubscriptionSuccess => ({
  type: RESUME_SUBSCRIPTION_SUCCESS,
  payload,
});

export const resumeSubscriptionFailure = (
  payload: ResumeSubscriptionFailurePayload,
): ResumeSubscriptionFailure => ({
  type: RESUME_SUBSCRIPTION_FAILURE,
  payload,
});

export const reactivateSubscriptionRequest = (
  payload: ReactivateSubscriptionRequestPayload,
): ReactivateSubscriptionRequest => ({
  type: REACTIVATE_SUBSCRIPTION_REQUEST,
  payload,
});

export const reactivateSubscriptionSuccess = (
  payload: ReactivateSubscriptionSuccessPayload,
): ReactivateSubscriptionSuccess => ({
  type: REACTIVATE_SUBSCRIPTION_SUCCESS,
  payload,
});

export const reactivateSubscriptionFailure = (
  payload: ReactivateSubscriptionFailurePayload,
): ReactivateSubscriptionFailure => ({
  type: REACTIVATE_SUBSCRIPTION_FAILURE,
  payload,
});

export const updateSubscriptionNextChargeDateRequest = (
  payload: UpdateSubscriptionNextChargeDateRequestPayload,
): UpdateSubscriptionNextChargeDateRequest => ({
  type: UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_REQUEST,
  payload,
});

export const updateSubscriptionNextChargeDateSuccess = (
  payload: UpdateSubscriptionNextChargeDateSuccessPayload,
): UpdateSubscriptionNextChargeDateSuccess => ({
  type: UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_SUCCESS,
  payload,
});

export const updateSubscriptionNextChargeDateFailure = (
  payload: UpdateSubscriptionNextChargeDateFailurePayload,
): UpdateSubscriptionNextChargeDateFailure => ({
  type: UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_FAILURE,
  payload,
});
