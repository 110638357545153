import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  getDefaultSubscriptionSelector,
  getSubscriptionsSelector,
} from '@modules/subscriptions/core/selectors';
import { ISubscription } from '../../../subscriptions/core/types';
import { useSwitchSubscription } from '@modules/settings/hooks/useSwitchSubscription';

export const SubscripstionsList = () => {
  const subscriptions = useSelector(getSubscriptionsSelector);
  const { switchDefaultSubscription } = useSwitchSubscription();

  const switchSubscription = (subscription: ISubscription) =>
    switchDefaultSubscription(subscription);

  const defaultSubscription = useSelector(getDefaultSubscriptionSelector);

  return (
    <div className="flex gap-[3.7rem] items-center">
      <p className="font-normal uppercase text-primary">
        Meine <span className="font-bold">Hunde</span>
      </p>
      <div className="flex gap-[2rem] items-center">
        {subscriptions?.map((subscription: ISubscription) => (
          <div key={subscription.id}>
            <button
              onClick={() => switchSubscription(subscription)}
              className={clsx(
                'flex justify-center items-center w-[4.4rem] h-[4.4rem] rounded-[3rem] text-white bg-primary font-bold',
                {
                  'opacity-50': subscription?.id !== defaultSubscription?.id,
                },
              )}
            >
              <span className="text-[18px]">{subscription?.pet?.name?.[0]}</span>
              <span className="hidden">{subscription?.pet?.name?.[0]}</span>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
