export const FETCH_SUBSCRIPTIONS_REQUEST = 'FETCH_SUBSCRIPTIONS_REQUEST';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'FETCH_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SUBSCRIPTIONS_FAILURE = 'FETCH_SUBSCRIPTIONS_REQUEST';

export const UPDATE_SUBSCRIPTION_ADDRESS_REQUEST = 'UPDATE_SUBSCRIPTION_ADDRESS_REQUEST';
export const UPDATE_SUBSCRIPTION_ADDRESS_SUCCESS = 'UPDATE_SUBSCRIPTION_ADDRESS_SUCCESS';
export const UPDATE_SUBSCRIPTION_ADDRESS_FAILURE = 'UPDATE_SUBSCRIPTION_ADDRESS_FAILURE';

export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';

export const FETCH_CANCELLATION_FLOW_REQUEST = 'FETCH_CANCELLATION_FLOW_REQUEST';
export const FETCH_CANCELLATION_FLOW_SUCCESS = 'FETCH_CANCELLATION_FLOW_SUCCESS';
export const FETCH_CANCELLATION_FLOW_FAILURE = 'FETCH_CANCELLATION_FLOW_FAILURE';

export const PAUSE_SUBSCRIPTION_REQUEST = 'PAUSE_SUBSCRIPTION_REQUEST';
export const PAUSE_SUBSCRIPTION_SUCCESS = 'PAUSE_SUBSCRIPTION_SUCCESS';
export const PAUSE_SUBSCRIPTION_FAILURE = 'PAUSE_SUBSCRIPTION_FAILURE';

export const RESUME_SUBSCRIPTION_REQUEST = 'RESUME_SUBSCRIPTION_REQUEST';
export const RESUME_SUBSCRIPTION_SUCCESS = 'RESUME_SUBSCRIPTION_SUCCESS';
export const RESUME_SUBSCRIPTION_FAILURE = 'RESUME_SUBSCRIPTION_FAILURE';

export const REACTIVATE_SUBSCRIPTION_REQUEST = 'REACTIVATE_SUBSCRIPTION_REQUEST';
export const REACTIVATE_SUBSCRIPTION_SUCCESS = 'REACTIVATE_SUBSCRIPTION_SUCCESS';
export const REACTIVATE_SUBSCRIPTION_FAILURE = 'REACTIVATE_SUBSCRIPTION_FAILURE';

export const UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_REQUEST =
  'UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_REQUEST';
export const UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_SUCCESS =
  'UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_SUCCESS';
export const UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_FAILURE =
  'UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_FAILURE';
