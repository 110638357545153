import {
  FETCH_ORDER_HISTORY_REQUEST,
  FETCH_ORDER_HISTORY_FAILURE,
  FETCH_ORDER_HISTORY_SUCCESS,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
  SKIP_ORDER_REQUEST,
  SKIP_ORDER_SUCCESS,
  SKIP_ORDER_FAILURE,
  UNSKIP_ORDER_REQUEST,
  UNSKIP_ORDER_SUCCESS,
  UNSKIP_ORDER_FAILURE,
} from './actionTypes';
import {
  FetchOrderHistoryRequest,
  FetchOrderHistorySuccess,
  FetchOrderHistorySuccessPayload,
  FetchOrderHistoryFailure,
  FetchOrderHistoryFailurePayload,
  FetchOrderHistoryRequestPayload,
  UpdateOrderRequest,
  UpdateOrderRequestPayload,
  UpdateOrderSuccess,
  UpdateOrderSuccessPayload,
  UpdateOrderFailure,
  UpdateOrderFailurePayload,
  PlaceOrderRequest,
  PlaceOrderRequestPayload,
  PlaceOrderSuccess,
  PlaceOrderSuccessPayload,
  PlaceOrderFailure,
  PlaceOrderFailurePayload,
  SkipOrderRequest,
  SkipOrderRequestPayload,
  SkipOrderSuccess,
  SkipOrderSuccessPayload,
  SkipOrderFailure,
  SkipOrderFailurePayload,
  UnskipOrderRequest,
  UnskipOrderRequestPayload,
  UnskipOrderSuccess,
  UnskipOrderSuccessPayload,
  UnskipOrderFailure,
  UnskipOrderFailurePayload,
} from './types';

export const fetchOrderHistoryRequest = (
  payload: FetchOrderHistoryRequestPayload,
): FetchOrderHistoryRequest => ({
  type: FETCH_ORDER_HISTORY_REQUEST,
  payload,
});

export const fetchOrderHistorySuccess = (
  payload: FetchOrderHistorySuccessPayload,
): FetchOrderHistorySuccess => ({
  type: FETCH_ORDER_HISTORY_SUCCESS,
  payload,
});

export const fetchOrderHistoryFailure = (
  payload: FetchOrderHistoryFailurePayload,
): FetchOrderHistoryFailure => ({
  type: FETCH_ORDER_HISTORY_FAILURE,
  payload,
});

export const updateOrderRequest = (payload: UpdateOrderRequestPayload): UpdateOrderRequest => ({
  type: UPDATE_ORDER_REQUEST,
  payload,
});

export const updateOrderSuccess = (payload: UpdateOrderSuccessPayload): UpdateOrderSuccess => ({
  type: UPDATE_ORDER_SUCCESS,
  payload,
});

export const updateOrderFailure = (payload: UpdateOrderFailurePayload): UpdateOrderFailure => ({
  type: UPDATE_ORDER_FAILURE,
  payload,
});

export const placeOrderRequest = (payload: PlaceOrderRequestPayload): PlaceOrderRequest => ({
  type: PLACE_ORDER_REQUEST,
  payload,
});

export const placeOrderSuccess = (payload: PlaceOrderSuccessPayload): PlaceOrderSuccess => ({
  type: PLACE_ORDER_SUCCESS,
  payload,
});

export const placeOrderFailure = (payload: PlaceOrderFailurePayload): PlaceOrderFailure => ({
  type: PLACE_ORDER_FAILURE,
  payload,
});

export const skipOrderRequest = (payload: SkipOrderRequestPayload): SkipOrderRequest => ({
  type: SKIP_ORDER_REQUEST,
  payload,
});

export const skipOrderSuccess = (payload: SkipOrderSuccessPayload): SkipOrderSuccess => ({
  type: SKIP_ORDER_SUCCESS,
  payload,
});

export const skipOrderFailure = (payload: SkipOrderFailurePayload): SkipOrderFailure => ({
  type: SKIP_ORDER_FAILURE,
  payload,
});

export const unskipOrderRequest = (payload: UnskipOrderRequestPayload): UnskipOrderRequest => ({
  type: UNSKIP_ORDER_REQUEST,
  payload,
});

export const unskipOrderSuccess = (payload: UnskipOrderSuccessPayload): UnskipOrderSuccess => ({
  type: UNSKIP_ORDER_SUCCESS,
  payload,
});

export const unskipOrderFailure = (payload: UnskipOrderFailurePayload): UnskipOrderFailure => ({
  type: UNSKIP_ORDER_FAILURE,
  payload,
});
