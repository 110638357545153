import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkIsActive, KTSVG } from '../../../../helpers';
import { useLayout } from '../../../core';

type AsideMenuItemProps = {
  children?: ReactNode;
  to: string;
  title: string;
  icon?: string;
  sub?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  cssClasses?: string[];
};

const AsideMenuItem = ({
  children,
  to,
  title,
  icon,
  sub,
  onClick,
  cssClasses,
}: AsideMenuItemProps) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { aside } = config;

  return (
    <div className={clsx('menu-item', cssClasses?.join(' '), { 'menu-item-sub': sub })}>
      <Link
        className={clsx('menu-link without-sub', { 'fill-black': !isActive, active: isActive })}
        to={to}
        onClick={onClick}
      >
        {icon && aside.menuIcon === 'svg' && (
          <span className="menu-icon">
            <KTSVG path={icon} className="svg-icon-2" />
          </span>
        )}
        <span className="menu-title">{title}</span>
      </Link>
      {children}
    </div>
  );
};

export { AsideMenuItem };
