import { createSelector } from 'reselect';
import { RootState } from 'src/store/store';

const getPending = (state: RootState) => state.settings.pending;

const getSettings = (state: RootState) => state.settings.data;

export const getSettingsSelector = createSelector(getSettings, (settings) => settings);

export const getPendingSelector = createSelector(getPending, (pending) => pending);
