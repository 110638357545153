export const mapOrderSchedule = (orderSchedule: any, subscription: any) => {
  return orderSchedule.map((order: any) => {
    return {
      ...order,
      currencyCode: subscription.currencyCode,
      lines: subscription.lines,
      shippingAddress: subscription.shippingAddress,
      totalLineItems: subscription.totalLineItems,
      paymentMethodId: subscription.paymentMethodId,
    };
  });
};
