import axios, { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  fetchSubscriptionsFailure,
  fetchSubscriptionsSuccess,
  updateSubscriptionAddressFailure,
  updateSubscriptionAddressRequest,
  updateSubscriptionAddressSuccess,
  cancelSubscriptionFailure,
  cancelSubscriptionRequest,
  cancelSubscriptionSuccess,
  fetchCancellationFlowFailure,
  fetchCancellationFlowRequest,
  fetchCancellationFlowSuccess,
  pauseSubscriptionFailure,
  pauseSubscriptionRequest,
  pauseSubscriptionSuccess,
  resumeSubscriptionFailure,
  resumeSubscriptionRequest,
  resumeSubscriptionSuccess,
  reactivateSubscriptionFailure,
  reactivateSubscriptionRequest,
  reactivateSubscriptionSuccess,
  updateSubscriptionNextChargeDateFailure,
  updateSubscriptionNextChargeDateRequest,
  updateSubscriptionNextChargeDateSuccess,
} from './actions';
import {
  FETCH_SUBSCRIPTIONS_REQUEST,
  UPDATE_SUBSCRIPTION_ADDRESS_REQUEST,
  CANCEL_SUBSCRIPTION_REQUEST,
  FETCH_CANCELLATION_FLOW_REQUEST,
  PAUSE_SUBSCRIPTION_REQUEST,
  RESUME_SUBSCRIPTION_REQUEST,
  REACTIVATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_REQUEST,
} from './actionTypes';
import { Status, ISubscription, SubscriptionParams, CancelSubscriptionParams } from './types';

const getSubscriptions = () => axios.get<ISubscription[]>(`/api/v1/subscriptions`);

const updateSubscriptionAddress = async (id: number, params: any) => {
  const response = await axios.post<ISubscription>(
    `/api/v1/subscriptions/${id}/update_address`,
    params,
  );
  return response.data;
};

const cancelSubscription = async (id: number, params: CancelSubscriptionParams) => {
  const response = await axios.post<ISubscription>(`/api/v1/subscriptions/${id}/cancel`, params);
  return response.data;
};

const fetchCancellationFlow = async (id: number) => {
  const response = await axios.post<ISubscription>(`/api/v1/subscriptions/${id}/cancellationFlow`);
  return response.data;
};

const pauseSubscription = async (id: number) => {
  const response = await axios.post<ISubscription>(`/api/v1/subscriptions/${id}/pause`);
  return response.data;
};

const resumeSubscription = async (id: number) => {
  const response = await axios.post<ISubscription>(`/api/v1/subscriptions/${id}/resume`);
  return response.data;
};

const reactivateSubscription = async (id: number) => {
  const response = await axios.post<ISubscription>(`/api/v1/subscriptions/${id}/reactivate`);
  return response.data;
};

const updateSubscriptionNextChargeDate = async (id: number, params: any) => {
  const response = await axios.post<ISubscription>(
    `/api/v1/subscriptions/${id}/next_charge_date`,
    params,
  );
  return response.data;
};

function* fetchSubscriptionsSaga() {
  try {
    const response: AxiosResponse<ISubscription[]> = yield call(getSubscriptions);

    yield put(
      fetchSubscriptionsSuccess({
        subscriptions: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchSubscriptionsFailure({
        error: e.message,
      }),
    );
  }
}

function* updateSubscriptionAddressSaga(
  action: ReturnType<typeof updateSubscriptionAddressRequest>,
) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      updateSubscriptionAddress(action.payload.id, action.payload.params),
    );

    yield put(
      updateSubscriptionAddressSuccess({
        subscription: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      updateSubscriptionAddressFailure({
        error: e.message,
      }),
    );
  }
}

function* cancelSubscriptionSaga(action: ReturnType<typeof cancelSubscriptionRequest>) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      cancelSubscription(action.payload.id, action.payload.params),
    );

    yield put(
      cancelSubscriptionSuccess({
        subscription: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      cancelSubscriptionFailure({
        error: e.message,
      }),
    );
  }
}

function* fetchCancellationFlowSaga(action: ReturnType<typeof fetchCancellationFlowRequest>) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      fetchCancellationFlow(action.payload.id),
    );

    yield put(
      fetchCancellationFlowSuccess({
        subscription: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchCancellationFlowFailure({
        error: e.message,
      }),
    );
  }
}

function* pauseSubscriptionSaga(action: ReturnType<typeof pauseSubscriptionRequest>) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      pauseSubscription(action.payload.id),
    );

    yield put(
      pauseSubscriptionSuccess({
        subscription: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      pauseSubscriptionFailure({
        error: e.message,
      }),
    );
  }
}

function* resumeSubscriptionSaga(action: ReturnType<typeof resumeSubscriptionRequest>) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      resumeSubscription(action.payload.id),
    );

    yield put(
      resumeSubscriptionSuccess({
        subscription: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      resumeSubscriptionFailure({
        error: e.message,
      }),
    );
  }
}

function* reactivateSubscriptionSaga(action: ReturnType<typeof reactivateSubscriptionRequest>) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      reactivateSubscription(action.payload.id),
    );

    yield put(
      reactivateSubscriptionSuccess({
        subscription: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      reactivateSubscriptionFailure({
        error: e.message,
      }),
    );
  }
}

function* updateSubscriptionNextChargeDateSaga(
  action: ReturnType<typeof updateSubscriptionNextChargeDateRequest>,
) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      updateSubscriptionNextChargeDate(action.payload.id, action.payload.params),
    );

    yield put(
      updateSubscriptionNextChargeDateSuccess({
        subscription: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      updateSubscriptionNextChargeDateFailure({
        error: e.message,
      }),
    );
  }
}

export function* subscriptionsSaga() {
  yield all([
    takeLatest(FETCH_SUBSCRIPTIONS_REQUEST, fetchSubscriptionsSaga),
    takeLatest(UPDATE_SUBSCRIPTION_ADDRESS_REQUEST, updateSubscriptionAddressSaga),
    takeLatest(CANCEL_SUBSCRIPTION_REQUEST, cancelSubscriptionSaga),
    takeLatest(FETCH_CANCELLATION_FLOW_REQUEST, fetchCancellationFlowSaga),
    takeLatest(PAUSE_SUBSCRIPTION_REQUEST, pauseSubscriptionSaga),
    takeLatest(RESUME_SUBSCRIPTION_REQUEST, resumeSubscriptionSaga),
    takeLatest(REACTIVATE_SUBSCRIPTION_REQUEST, reactivateSubscriptionSaga),
    takeLatest(UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_REQUEST, updateSubscriptionNextChargeDateSaga),
  ]);
}
