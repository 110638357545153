import {
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
} from './actionTypes';

export enum CustomerStatus {
  ACTIVE,
  DISABLED,
}

export interface ICustomer {
  id?: number;
  hash?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  shopify_customer_id?: string;
  status?: CustomerStatus;
}

export interface CustomerState {
  pending: boolean;
  data: ICustomer | null;
  error: string | null;
}

export interface FetchCustomerSuccessPayload {
  customer: ICustomer;
}

export interface FetchCustomerFailurePayload {
  error: string;
}

export interface FetchCustomerRequest {
  type: typeof FETCH_CUSTOMER_REQUEST;
}

export type FetchCustomerSuccess = {
  type: typeof FETCH_CUSTOMER_SUCCESS;
  payload: FetchCustomerSuccessPayload;
};

export type FetchCustomerFailure = {
  type: typeof FETCH_CUSTOMER_FAILURE;
  payload: FetchCustomerFailurePayload;
};

// UPDATE
export interface UpdateCustomerRequestPayload {
  id: number;
  params: any;
}

export interface UpdateCustomerSuccessPayload {
  customer: ICustomer;
}

export interface UpdateCustomerFailurePayload {
  error: string;
}

export interface UpdateCustomerRequest {
  type: typeof UPDATE_CUSTOMER_REQUEST;
  payload: UpdateCustomerRequestPayload;
}

export type UpdateCustomerSuccess = {
  type: typeof UPDATE_CUSTOMER_SUCCESS;
  payload: UpdateCustomerSuccessPayload;
};

export type UpdateCustomerFailure = {
  type: typeof UPDATE_CUSTOMER_FAILURE;
  payload: UpdateCustomerFailurePayload;
};

export type CustomerActions =
  | FetchCustomerRequest
  | FetchCustomerSuccess
  | FetchCustomerFailure
  | UpdateCustomerRequest
  | UpdateCustomerSuccess
  | UpdateCustomerFailure
  | any;
