import {
  UPDATE_SETTINGS,
  SWITCH_SUBSCRIPTION_REQUEST,
  SWITCH_SUBSCRIPTION_SUCCESS,
  SWITCH_SUBSCRIPTION_FAILURE,
} from './actionTypes';
import {
  UpdateSettings,
  SwitchSubscriptionRequestPayload,
  SwitchSubscriptionRequest,
  SwitchSubscriptionSuccessPayload,
  SwitchSubscriptionSuccess,
  SwitchSubscriptionFailurePayload,
  SwitchSubscriptionFailure,
} from './types';

export const updateSettings = (payload: any): UpdateSettings => ({
  type: UPDATE_SETTINGS,
  payload,
});

export const switchSubscriptionRequest = (
  payload: SwitchSubscriptionRequestPayload,
): SwitchSubscriptionRequest => ({
  type: SWITCH_SUBSCRIPTION_REQUEST,
  payload,
});

export const switchSubscriptionSuccess = (
  payload: SwitchSubscriptionSuccessPayload,
): SwitchSubscriptionSuccess => ({
  type: SWITCH_SUBSCRIPTION_SUCCESS,
  payload,
});

export const switchSubscriptionFailure = (
  payload: SwitchSubscriptionFailurePayload,
): SwitchSubscriptionFailure => ({
  type: SWITCH_SUBSCRIPTION_FAILURE,
  payload,
});
