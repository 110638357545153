import axios, { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  fetchPaymentMethodsFailure,
  fetchPaymentMethodsSuccess,
  updatePaymentMethodFailure,
  updatePaymentMethodRequest,
  updatePaymentMethodSuccess,
} from './actions';
import { FETCH_PAYMENT_METHODS_REQUEST, UPDATE_PAYMENT_METHOD_REQUEST } from './actionTypes';
import { IPaymentMethods } from './types';

const getPaymentMethods = () => axios.get<IPaymentMethods>('/api/v1/payments');

const updatePaymentMethod = async (id: number) => {
  const response = await axios.post<any>(`/api/v1/payments/${id}`);
  return response.data;
};

function* fetchPaymentMethodsSaga() {
  try {
    const response: AxiosResponse<IPaymentMethods> = yield call(getPaymentMethods);

    yield put(
      fetchPaymentMethodsSuccess({
        paymentMethods: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchPaymentMethodsFailure({
        error: e.message,
      }),
    );
  }
}

function* updatePaymentMethodSaga(action: ReturnType<typeof updatePaymentMethodRequest>) {
  try {
    const response: AxiosResponse<IPaymentMethods> = yield call(() =>
      updatePaymentMethod(action.payload.id),
    );

    yield put(
      updatePaymentMethodSuccess({
        address: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      updatePaymentMethodFailure({
        error: e.message,
      }),
    );
  }
}

export function* paymentMethodsSaga() {
  yield takeLatest(FETCH_PAYMENT_METHODS_REQUEST, fetchPaymentMethodsSaga);
  yield takeLatest(UPDATE_PAYMENT_METHOD_REQUEST, updatePaymentMethodSaga);
}
