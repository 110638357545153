import {
  FETCH_ORDER_SCHEDULE_REQUEST,
  FETCH_ORDER_SCHEDULE_SUCCESS,
  FETCH_ORDER_SCHEDULE_FAILURE,
} from './actionTypes';

import { OrderScheduleActions, OrderScheduleState } from './types';

const initialState: OrderScheduleState = {
  pending: false,
  data: [],
  error: null,
};

export const orderScheduleReducer = (state = initialState, action: OrderScheduleActions) => {
  switch (action.type) {
    case FETCH_ORDER_SCHEDULE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_ORDER_SCHEDULE_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload.orderSchedule.data,
        error: null,
      };
    case FETCH_ORDER_SCHEDULE_FAILURE:
      return {
        ...state,
        pending: false,
        data: [],
        error: action.payload.error,
      };
    case 'persist/REHYDRATE':
      return {
        ...state,
        data:
          action.payload && action.payload.orderSchedule && action.payload.orderSchedule.data
            ? action.payload.orderSchedule.data
            : [],
      };
    default:
      return {
        ...state,
      };
  }
};
