import { useEffect } from 'react';

export const GeorgiaChatWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'gorgias-chat-widget-install-v3';
    script.src = 'https://config.gorgias.chat/bundle-loader/01GYCC48PFFC95D8C1E6YP1G5Y';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return <></>;
};
