import {
  FETCH_AUTH_REQUEST,
  FETCH_AUTH_FAILURE,
  FETCH_AUTH_SUCCESS,
  FETCH_REAUTH_REQUEST,
  FETCH_REAUTH_FAILURE,
  FETCH_REAUTH_SUCCESS,
  FETCH_AUTH_LOGOUT_REQUEST,
  FETCH_AUTH_VIA_SESSION_REQUEST,
  FETCH_AUTH_VIA_SESSION_SUCCESS,
  FETCH_AUTH_VIA_SESSION_FAILURE,
} from './actionTypes';
import {
  FetchAuthRequestPayload,
  FetchAuthRequest,
  FetchAuthSuccess,
  FetchAuthSuccessPayload,
  FetchAuthFailure,
  FetchAuthFailurePayload,
  FetchReAuthRequestPayload,
  FetchReAuthRequest,
  FetchReAuthSuccess,
  FetchReAuthSuccessPayload,
  FetchReAuthFailure,
  FetchReAuthFailurePayload,
  FetchAuthViaSessionRequestPayload,
  FetchAuthViaSessionRequest,
  FetchAuthViaSessionSuccessPayload,
  FetchAuthViaSessionSuccess,
  FetchAuthViaSessionFailurePayload,
  FetchAuthViaSessionFailure,
} from './types';

export const fetchAuthRequest = (payload: FetchAuthRequestPayload): FetchAuthRequest => ({
  type: FETCH_AUTH_REQUEST,
  payload,
});

export const fetchAuthSuccess = (payload: FetchAuthSuccessPayload): FetchAuthSuccess => ({
  type: FETCH_AUTH_SUCCESS,
  payload,
});

export const fetchAuthFailure = (payload: FetchAuthFailurePayload): FetchAuthFailure => ({
  type: FETCH_AUTH_FAILURE,
  payload,
});

export const fetchAuthViaSessionRequest = (
  payload: FetchAuthViaSessionRequestPayload,
): FetchAuthViaSessionRequest => ({
  type: FETCH_AUTH_VIA_SESSION_REQUEST,
  payload,
});

export const fetchAuthViaSessionSuccess = (
  payload: FetchAuthViaSessionSuccessPayload,
): FetchAuthViaSessionSuccess => ({
  type: FETCH_AUTH_VIA_SESSION_SUCCESS,
  payload,
});

export const fetchAuthViaSessionFailure = (
  payload: FetchAuthViaSessionFailurePayload,
): FetchAuthViaSessionFailure => ({
  type: FETCH_AUTH_VIA_SESSION_FAILURE,
  payload,
});

export const fetchReAuthRequest = (payload: FetchReAuthRequestPayload): FetchReAuthRequest => ({
  type: FETCH_REAUTH_REQUEST,
  payload,
});

export const fetchReAuthSuccess = (payload: FetchReAuthSuccessPayload): FetchReAuthSuccess => ({
  type: FETCH_REAUTH_SUCCESS,
  payload,
});

export const fetchReAuthFailure = (payload: FetchReAuthFailurePayload): FetchReAuthFailure => ({
  type: FETCH_REAUTH_FAILURE,
  payload,
});

export const fetchAuthLogoutRequest = (): any => ({
  type: FETCH_AUTH_LOGOUT_REQUEST,
});
