import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { fetchAuthLogoutRequest } from './core/actions';

export function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAuthLogoutRequest());

    setTimeout(() => {
      localStorage.clear();
      console.log('Local storage cleared');
    }, 500);
  }, [dispatch]);

  return <Navigate to="auth" />;
}
