import axios, { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  fetchAddressesSuccess,
  fetchAddressesFailure,
  updateAddressRequest,
  updateAddressSuccess,
  updateAddressFailure,
  createAddressRequest,
  createAddressSuccess,
  createAddressFailure,
} from './actions';
import {
  FETCH_ADDRESSES_REQUEST,
  UPDATE_ADDRESS_REQUEST,
  CREATE_ADDRESS_REQUEST,
} from './actionTypes';
import { IAddress } from './types';

const getAddresses = () => axios.get<IAddress>(`/api/v1/addresses`);

const updateAddress = async (id: number, params: any) => {
  const response = await axios.put<IAddress>(`/api/v1/addresses/${id}`, params);
  return response.data;
};

const createAddress = async (params: any) => {
  const response = await axios.post<any>(`/api/v1/addresses/`, params);
  return response.data;
};

function* fetchAddressesSaga() {
  try {
    const response: AxiosResponse<IAddress> = yield call(getAddresses);

    yield put(
      fetchAddressesSuccess({
        addresses: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchAddressesFailure({
        error: e.message,
      }),
    );
  }
}

function* updateAddressSaga(action: ReturnType<typeof updateAddressRequest>) {
  try {
    const response: AxiosResponse<IAddress> = yield call(() =>
      updateAddress(action.payload.id, action.payload.params),
    );

    yield put(
      updateAddressSuccess({
        address: { ...action.payload.params, id: action.payload.id },
      }),
    );
  } catch (e: any) {
    yield put(
      updateAddressFailure({
        error: e.message,
      }),
    );
  }
}

function* createAddressSaga(action: ReturnType<typeof createAddressRequest>) {
  try {
    const response: AxiosResponse<IAddress> = yield call(() =>
      createAddress(action.payload.params),
    );

    yield put(
      createAddressSuccess({
        address: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      createAddressFailure({
        error: e.message,
      }),
    );
  }
}

export function* addressesSaga() {
  yield all([
    takeLatest(FETCH_ADDRESSES_REQUEST, fetchAddressesSaga),
    takeLatest(UPDATE_ADDRESS_REQUEST, updateAddressSaga),
    takeLatest(CREATE_ADDRESS_REQUEST, createAddressSaga),
  ]);
}
