import {ReactNode, useEffect, useRef} from 'react'
import {useClickOutside} from '../../hooks/useClickOutside'
import {Portal} from '../portal/Portal'

type Props = {
  isOpen?: boolean
  children?: ReactNode
  handleClose?: any
}

export function Modal({isOpen, children, handleClose}: Props) {
  const ref = useRef<HTMLDivElement | null>(null)
  useClickOutside(ref, () => {
    handleClose()
  })

  const handleEsc = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      handleClose()
    }
  }
  useEffect(() => {
    if (ref.current && isOpen) {
      ref.current.focus()
    }
  }, [isOpen])

  useEffect(() => {
    window.addEventListener('keydown', handleEsc, false)

    return () => {
      window.removeEventListener('keydown', handleEsc, false)
    }
  })

  if (!isOpen) {
    return null
  }

  return (
    <Portal className='modal'>
      <div ref={ref} className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <button className='modal-close' onClick={handleClose}></button>
          </div>
          <div className='modal-body'>{children}</div>
        </div>
      </div>
    </Portal>
  )
}
