import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
} from './actionTypes';

import { ProductsActions, ProductsState } from './types';

const initialState: ProductsState = {
  pending: false,
  data: [],
  error: null,
};

export const productsReducer = (state = initialState, action: ProductsActions) => {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload.products,
        error: null,
      };
    case FETCH_PRODUCTS_FAILURE:
      console.warn(action);
      return {
        ...state,
        pending: false,
        data: [],
        error: null,
      };
    default:
      return {
        ...state,
      };
  }
};
