import {BrowserStorage} from './browserStorage'

export class IdentityRepository {
  private _key = 'identity'
  private _hostProvisionsKey = 'hostProvisionKeys'
  private _storage: BrowserStorage<any>

  constructor(storage: BrowserStorage<any>) {
    this._storage = storage
  }

  public getIdentity = () => this._storage.get(this._key)

  public saveIdentity = (identity: any) => {
    this._storage.save(this._key, identity)
  }

  public updateIdentity = (identity: any) => {
    console.log('updateIdentity', this._storage.get(this._key))
    const data = this._storage.get(this._key)
    if (data) {
      const updatedIdentity = {
        ...data,
        ...identity,
      }
      this._storage.save(this._key, updatedIdentity)
    }
  }
  public deleteIdentity = () => {
    this._storage.delete(this._key)
    this._storage.delete(this._hostProvisionsKey)
  }
}
