import axios from 'axios';

const setupAxios = (store: any) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URI;
  axios.defaults.withCredentials = true;

  axios.interceptors.request.use(
    (config) => {
      const state = store.getState();
      const accessToken = state.auth.data.accessToken;
      if (accessToken) {
        config.headers = config.headers || {};
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async function (error) {
      const originalRequest = error.config;

      console.log(originalRequest);
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          const response = await fetch('/api/v1/refresh', {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (!response.ok) {
            localStorage.clear();
            window.location.href = '/auth';
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const data = await response.json();
          console.log('data', data);
        } catch (refreshError) {
          console.log('Failed to refresh token:', refreshError);
          localStorage.clear();
          window.location.href = '/auth';
          return Promise.reject(null);
        }
      }
      return Promise.reject(error);
    },
  );
};

export default setupAxios;
