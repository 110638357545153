import {useMemo} from 'react'
import {BrowserStorage} from '../utils/browserStorage'
import {IdentityRepository} from '../utils/identityRepository'

export function useIdentityRepository() {
  const isBrowser = () => typeof window !== 'undefined'

  const repository = useMemo(() => {
    if (isBrowser()) {
      const storage = new BrowserStorage<any>(localStorage, JSON)
      return new IdentityRepository(storage)
    }
  }, [])

  return repository
}
