import {
  FETCH_SUBSCRIPTIONS_REQUEST,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_FAILURE,
  UPDATE_SUBSCRIPTION_ADDRESS_REQUEST,
  UPDATE_SUBSCRIPTION_ADDRESS_SUCCESS,
  UPDATE_SUBSCRIPTION_ADDRESS_FAILURE,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  FETCH_CANCELLATION_FLOW_REQUEST,
  FETCH_CANCELLATION_FLOW_SUCCESS,
  FETCH_CANCELLATION_FLOW_FAILURE,
  PAUSE_SUBSCRIPTION_REQUEST,
  PAUSE_SUBSCRIPTION_SUCCESS,
  PAUSE_SUBSCRIPTION_FAILURE,
  RESUME_SUBSCRIPTION_REQUEST,
  RESUME_SUBSCRIPTION_SUCCESS,
  RESUME_SUBSCRIPTION_FAILURE,
  REACTIVATE_SUBSCRIPTION_REQUEST,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
  REACTIVATE_SUBSCRIPTION_FAILURE,
  UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_REQUEST,
  UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_SUCCESS,
  UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_FAILURE,
} from './actionTypes';

import { SubscriptionsActions, SubscriptionsState } from './types';

const initialState: SubscriptionsState = {
  pending: false,
  data: [],
  error: null,
};

export const subscriptionsReducer = (state = initialState, action: SubscriptionsActions) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_SUBSCRIPTIONS_SUCCESS:
      // const filterSubscriptions = action.payload.subscriptions.data.filter(
      //   (subscription: any) => subscription.pet !== null,
      // );
      return {
        ...state,
        pending: false,
        data: action.payload.subscriptions.data,
        error: null,
      };
    case FETCH_SUBSCRIPTIONS_FAILURE:
      console.warn(action);
      return {
        ...state,
        pending: false,
        data: [],
        error: null,
      };
    case UPDATE_SUBSCRIPTION_ADDRESS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_SUBSCRIPTION_ADDRESS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) => {
          return subscription.id === action.payload.subscription.id
            ? action.payload.subscription
            : subscription;
        }),
        error: null,
      };
    case UPDATE_SUBSCRIPTION_ADDRESS_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };
    case UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.subscription.id
            ? {
                ...subscription,
                data: {
                  ...subscription.data,
                  ...action.payload.subscription,
                },
              }
            : subscription,
        ),
        error: null,
      };
    case UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };

    case CANCEL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.subscription.id
            ? {
                ...subscription,
                data: {
                  ...subscription.data,
                  ...action.payload.subscription,
                },
              }
            : subscription,
        ),
        error: null,
      };
    case CANCEL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };

    case FETCH_CANCELLATION_FLOW_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CANCELLATION_FLOW_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.subscription.data.id
            ? {
                ...subscription,
                data: {
                  ...subscription.data,
                  ...action.payload.subscription.data,
                },
              }
            : subscription,
        ),
        error: null,
      };
    case FETCH_CANCELLATION_FLOW_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };

    case PAUSE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case PAUSE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.subscription.data.id
            ? {
                ...subscription,
                data: {
                  ...subscription.data,
                  ...action.payload.subscription.data,
                },
              }
            : subscription,
        ),
        error: null,
      };
    case PAUSE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };

    case RESUME_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case RESUME_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.subscription.data.id
            ? {
                ...subscription,
                data: {
                  ...subscription.data,
                  ...action.payload.subscription.data,
                },
              }
            : subscription,
        ),
        error: null,
      };
    case RESUME_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };

    case REACTIVATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REACTIVATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.subscription.data.id
            ? {
                ...subscription,
                data: {
                  ...subscription.data,
                  ...action.payload.subscription.data,
                },
              }
            : subscription,
        ),
        error: null,
      };
    case REACTIVATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };

    case 'persist/REHYDRATE':
      return {
        ...state,
        data:
          action.payload && action.payload.subscriptions && action.payload.subscriptions.data
            ? action.payload.subscriptions.data
            : [],
      };
    default:
      return {
        ...state,
      };
  }
};
