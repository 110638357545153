export type MenuItem = {
  id: string;
  to: string;
  title: string;
  pet?: boolean;
  icon: string;
};

export const MENU_ITEMS: MenuItem[] = [
  {
    id: 'dashboard',
    to: '/dashboard',
    title: 'MENU.DASHBOARD',
    icon: '/media/svg/aside/dashboard.svg',
  },
  {
    id: 'orders',
    to: '/orders',
    title: 'MENU.ORDERS',
    icon: '/media/svg/aside/order.svg',
  },
  {
    id: 'subscriptions',
    to: '/subscriptions',
    title: 'MENU.SUBSCRIPTIONS',
    pet: true,
    icon: '/media/svg/aside/bowl.svg',
  },
];

export const NAVI = [
  ...MENU_ITEMS,
  {
    id: 'profile',
    to: '/profile',
    title: 'MENU.PROFILE',
    icon: '/media/svg/aside/paw.svg',
  },
];
