import { KTSVG } from '../../../../../shared/helpers';
import { toAbsoluteUrl } from '../../../../../shared/helpers';
import { useSelector } from 'react-redux';
import { getCurrentOrderSelector } from '@modules/order-schedule/core/selectors';
import { ISachet } from 'src/app/modules/products/core/types';
import { Widget } from '@components/index';

export const RecipeOverview = () => {
  const order = useSelector(getCurrentOrderSelector);

  const lineItem = order?.lines?.[0];
  if (!order) return <p>No current order available.</p>;

  return (
    <Widget
      header={
        <p>
          <b>Rezepte</b> Übersicht
        </p>
      }
      footer={
        order?.lines && <p>{lineItem?.price.toLocaleString('de-DE').replace('.', ',')} EUR</p>
      }
    >
      <div className="bg-light-100 widget-recipe-wrapper">
        <div className="relative flex items-center justify-center font-medium text-primary">
          <div className="z-50">
            <img src="/media/img/misc/meal.png" alt="meal" width="100px" height="100px" />
          </div>
          <span className="widget-recipe-kcal">{lineItem?.productTitle}</span>
        </div>
        <div className="flex gap-[3rem] justify-center font-bold mb-[1.5rem] lg:text-[1.6rem]">
          <div className="flex items-center gap-2">
            <div>
              <KTSVG path={toAbsoluteUrl('/media/svg/general/checkmark.svg')} />
            </div>
            <div>Alle {lineItem?.product?.options?.cycle} Tage</div>
          </div>
          <div className="flex items-center gap-2">
            <div>
              <KTSVG path={toAbsoluteUrl('/media/svg/general/checkmark.svg')} />
            </div>
            <div>Vollpension</div>
          </div>
        </div>
        <ul className="mb-[2rem]">
          {lineItem?.product?.sachets.map((sachet: ISachet) => {
            if (sachet.quantity === 0) return null;
            return (
              <li key={sachet.type} data-key={sachet.type}>
                {sachet.quantity} x {sachet.weight.value}
                {sachet.weight.unit} {sachet.type} (reicht {sachet.serve} Tage)
              </li>
            );
          })}
        </ul>
      </div>
    </Widget>
  );
};
