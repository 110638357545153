import { all, call, put, takeLatest } from 'redux-saga/effects';

import { UPDATE_SETTINGS, SWITCH_SUBSCRIPTION_REQUEST } from './actionTypes';
import {
  updateSettings,
  switchSubscriptionFailure,
  switchSubscriptionRequest,
  switchSubscriptionSuccess,
} from './actions';
import axios, { AxiosResponse } from 'axios';
import { ISubscription } from '@modules/subscriptions/core/types';

const switchSubscription = async (id: number) =>
  await axios.post<ISubscription>(`/api/v1/subscriptions/${id}/switch`);

function* updateSettingsSaga(action: any): Generator {
  try {
    yield call(() =>
      updateSettings({
        data: action.payload,
      }),
    );
  } catch (e: any) {}
}

function* switchSubscriptionSaga(action: ReturnType<typeof switchSubscriptionRequest>) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      switchSubscription(action.payload.id),
    );

    yield put(
      switchSubscriptionSuccess({
        subscription: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      switchSubscriptionFailure({
        error: e.message,
      }),
    );
  }
}

export function* settingsSaga() {
  yield all([
    takeLatest(UPDATE_SETTINGS, updateSettingsSaga),
    takeLatest(SWITCH_SUBSCRIPTION_REQUEST, switchSubscriptionSaga),
  ]);
}
