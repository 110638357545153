import axios, { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  fetchOrderHistoryFailure,
  fetchOrderHistoryRequest,
  fetchOrderHistorySuccess,
  updateOrderRequest,
  updateOrderSuccess,
  updateOrderFailure,
  placeOrderRequest,
  placeOrderSuccess,
  placeOrderFailure,
  skipOrderRequest,
  skipOrderSuccess,
  skipOrderFailure,
  unskipOrderRequest,
  unskipOrderSuccess,
  unskipOrderFailure,
} from './actions';
import {
  FETCH_ORDER_HISTORY_REQUEST,
  UPDATE_ORDER_REQUEST,
  PLACE_ORDER_REQUEST,
  SKIP_ORDER_REQUEST,
  UNSKIP_ORDER_REQUEST,
} from './actionTypes';
import { IOrder } from './types';

const getOrderHistory = async (subscriptionId: number) =>
  await axios.get<any>(`/api/v1/subscriptions/${subscriptionId}/order_history`);

const updateOrder = async (id: number, params: any) => {
  const response = await axios.put<IOrder[]>(`/api/v1/orders/${id}`, params);
  return response.data;
};

const placeOrder = async (subscriptionId: number) => {
  const response = await axios.post<any>(`/api/v1/subscriptions/${subscriptionId}/place_order`);
  return response.data;
};

const skipOrder = async (id: number) => {
  const response = await axios.post<any>(`/api/v1/orders/${id}/skip`);
  return response.data;
};
const unskipOrder = async (id: number) => {
  const response = await axios.post<any>(`/api/v1/orders/${id}/unskip`);
  return response.data;
};

function* fetchOrderHistorySaga(action: ReturnType<typeof fetchOrderHistoryRequest>) {
  try {
    const response: AxiosResponse<any> = yield call(() =>
      getOrderHistory(action.payload.subscriptionId),
    );

    yield put(
      fetchOrderHistorySuccess({
        orders: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchOrderHistoryFailure({
        error: e.message,
      }),
    );
  }
}

function* updateOrderSaga(action: ReturnType<typeof updateOrderRequest>) {
  try {
    const response: AxiosResponse<IOrder> = yield call(() =>
      updateOrder(action.payload.id, action.payload.params),
    );

    yield put(
      updateOrderSuccess({
        order: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      updateOrderFailure({
        error: e.message,
      }),
    );
  }
}

function* placeOrderSaga(action: ReturnType<typeof placeOrderRequest>) {
  try {
    const response: AxiosResponse<any> = yield call(() => placeOrder(action.payload.id));

    yield put(
      placeOrderSuccess({
        order: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      placeOrderFailure({
        error: e.message,
      }),
    );
  }
}

function* skipOrderSaga(action: ReturnType<typeof skipOrderRequest>) {
  try {
    const response: AxiosResponse<IOrder> = yield call(() => skipOrder(action.payload.id));

    yield put(
      skipOrderSuccess({
        order: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      skipOrderFailure({
        error: e.message,
      }),
    );
  }
}

function* unskipOrderSaga(action: ReturnType<typeof unskipOrderRequest>) {
  try {
    const response: AxiosResponse<IOrder> = yield call(() => unskipOrder(action.payload.id));

    yield put(
      unskipOrderSuccess({
        order: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      unskipOrderFailure({
        error: e.message,
      }),
    );
  }
}

export function* ordersSaga() {
  yield all([
    takeLatest(FETCH_ORDER_HISTORY_REQUEST, fetchOrderHistorySaga),
    takeLatest(UPDATE_ORDER_REQUEST, updateOrderSaga),
    takeLatest(PLACE_ORDER_REQUEST, placeOrderSaga),
    takeLatest(SKIP_ORDER_REQUEST, skipOrderSaga),
    takeLatest(UNSKIP_ORDER_REQUEST, unskipOrderSaga),
  ]);
}
