import {
  FETCH_AUTH_REQUEST,
  FETCH_AUTH_SUCCESS,
  FETCH_AUTH_FAILURE,
  FETCH_AUTH_LOGOUT_REQUEST,
  FETCH_REAUTH_REQUEST,
  FETCH_REAUTH_SUCCESS,
  FETCH_REAUTH_FAILURE,
  FETCH_AUTH_VIA_SESSION_REQUEST,
  FETCH_AUTH_VIA_SESSION_SUCCESS,
  FETCH_AUTH_VIA_SESSION_FAILURE,
} from './actionTypes';

import { AuthActions, AuthState } from './types';

const initialState: AuthState = {
  pending: false,
  data: {},
  error: null,
};

export const authReducer = (state = initialState, action: AuthActions) => {
  switch (action.type) {
    case FETCH_AUTH_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_AUTH_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload.auth,
        error: null,
      };
    case FETCH_AUTH_FAILURE:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload.error,
      };
    case FETCH_AUTH_VIA_SESSION_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_AUTH_VIA_SESSION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload.auth,
        error: null,
      };
    case FETCH_AUTH_VIA_SESSION_FAILURE:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload.error,
      };
    case FETCH_REAUTH_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_REAUTH_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload.auth,
        error: null,
      };
    case FETCH_REAUTH_FAILURE:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload.error,
      };
    // TODO: improve - remove custom check
    case FETCH_AUTH_LOGOUT_REQUEST:
      return initialState;
    case 'persist/REHYDRATE':
      return {
        ...state,
        data:
          action.payload && action.payload.auth && action.payload.auth.data
            ? action.payload.auth.data
            : {},
      };
    default:
      return {
        ...state,
      };
  }
};
