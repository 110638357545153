import {
  FETCH_ORDER_SCHEDULE_REQUEST,
  FETCH_ORDER_SCHEDULE_FAILURE,
  FETCH_ORDER_SCHEDULE_SUCCESS,
} from './actionTypes';
import {
  FetchOrderScheduleRequest,
  FetchOrderScheduleSuccess,
  FetchOrderScheduleSuccessPayload,
  FetchOrderScheduleFailure,
  FetchOrderScheduleFailurePayload,
  FetchOrderScheduleRequestPayload,
} from './types';

export const fetchOrderScheduleRequest = (
  payload: FetchOrderScheduleRequestPayload,
): FetchOrderScheduleRequest => ({
  type: FETCH_ORDER_SCHEDULE_REQUEST,
  payload,
});

export const fetchOrderScheduleSuccess = (
  payload: FetchOrderScheduleSuccessPayload,
): FetchOrderScheduleSuccess => ({
  type: FETCH_ORDER_SCHEDULE_SUCCESS,
  payload,
});

export const fetchOrderScheduleFailure = (
  payload: FetchOrderScheduleFailurePayload,
): FetchOrderScheduleFailure => ({
  type: FETCH_ORDER_SCHEDULE_FAILURE,
  payload,
});
