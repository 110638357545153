import { createSelector } from 'reselect';
import { RootState } from 'src/store/store';
import { ISubscription } from './types';

const getSettings = (state: RootState) => state.settings.data;

const getPending = (state: RootState) => state.subscriptions.pending;

const getSubscriptions = (state: RootState) => state.subscriptions.data;

const getError = (state: RootState) => state.subscriptions.error;

export const getSubscriptionsSelector = createSelector(getSubscriptions, (subscriptions) =>
  subscriptions.filter(
    (subscription: any) =>
      subscription.status === 'ACTIVE' &&
      subscription.lines?.every((lineItem: any) => lineItem.product !== null),
  ),
);

export const getCancelledSubscriptionsSelector = createSelector(getSubscriptions, (subscriptions) =>
  subscriptions.filter((subscription: any) => subscription.status === 'CANCELLED'),
);

export const getDefaultSubscriptionSelector = createSelector(
  [getSubscriptions, getSettings],
  (subscriptions, settings) => {
    return subscriptions?.find(
      (subscription: ISubscription) => subscription?.id === settings.defaultSubscription,
    );
  },
);

export const getPetSelector = createSelector(
  [getDefaultSubscriptionSelector],
  (defaultSubscription) => defaultSubscription?.pet,
);

export const getPendingSelector = createSelector(getPending, (pending) => pending);

export const getErrorSelector = createSelector(getError, (error) => error);
