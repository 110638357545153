/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { ForgotPassword } from './components/ForgotPassword';
// import { Login } from './components/Login';
import { LoginViaSession } from './components/LoginViaSession';

const AuthLayout = () => {
  useEffect(() => {
    // document.body.style.backgroundImage = 'none'
    // document.body.style.background =
    //   'linear-gradient(180deg, rgba(255,255,255,1) 65%, rgba(226,236,231,1) 85%, rgba(201,220,209,1) 100%)'
    return () => {};
  }, []);

  return (
    <div className="h-full">
      <Outlet />
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<LoginViaSession />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route index element={<LoginViaSession />} />
    </Route>
  </Routes>
);

export { AuthPage };
