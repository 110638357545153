import {
  FETCH_ADDRESSES_REQUEST,
  FETCH_ADDRESSES_SUCCESS,
  FETCH_ADDRESSES_FAILURE,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAILURE,
  CREATE_ADDRESS_REQUEST,
  CREATE_ADDRESS_SUCCESS,
  CREATE_ADDRESS_FAILURE,
} from './actionTypes';

import { AddressesActions, AddressesState } from './types';

const initialState: AddressesState = {
  pending: false,
  data: [],
  error: null,
};

export const addressesReducer = (state = initialState, action: AddressesActions) => {
  switch (action.type) {
    case FETCH_ADDRESSES_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_ADDRESSES_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload.addresses.data,
        error: null,
      };
    case FETCH_ADDRESSES_FAILURE:
      return {
        ...state,
        pending: false,
        data: [],
        error: action.payload.error,
      };
    case UPDATE_ADDRESS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((address) =>
          address.id === action.payload.address.id ? action.payload.address : address,
        ),
        error: null,
      };
    case UPDATE_ADDRESS_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };
    case CREATE_ADDRESS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CREATE_ADDRESS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: [...state.data, action.payload.address],
        error: null,
      };
    case CREATE_ADDRESS_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };
    case 'persist/REHYDRATE':
      return {
        ...state,
        data:
          action.payload && action.payload.addresses && action.payload.addresses.data
            ? action.payload.addresses.data
            : [],
      };
    default:
      return {
        ...state,
      };
  }
};
