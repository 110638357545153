import { Link } from 'react-router-dom';
import { KTSVG } from '../../../../../shared/helpers/index';
import { toAbsoluteUrl } from '../../../../../shared/helpers/index';
import { useSelector } from 'react-redux';
import {
  getCurrentOrderSelector,
  getOrderScheduleSelector,
} from '@modules/order-schedule/core/selectors';
import { FormattedDate } from 'react-intl';
import { Widget } from '@components/index';

export type SingleOrderSummaryProps = {
  order: any;
};

export const UpcomingOrders = ({ order }: SingleOrderSummaryProps) => {
  const orderInfo = useSelector(getCurrentOrderSelector);
  const lineItem = order?.lines?.[0];

  const orders = useSelector(getOrderScheduleSelector);

  if (!orders.length) return <p>No orders available.</p>;

  return (
    <Widget
      header={
        <p>
          Deine nächsten
          <span className="font-bold"> Bestellungen</span>
        </p>
      }
      footer={
        <Link to="/orders" className="order-link">
          Weitere Bestellungen anzeigen
          <KTSVG
            path={toAbsoluteUrl('/media/svg/general/link.svg')}
            className="w-[1.7rem] fill-primary"
          />
        </Link>
      }
    >
      <div className="flex items-center justify-center mt-[-3rem]">
        <div className="z-10 -mr-4 bg-white rounded-full">
          <KTSVG path={toAbsoluteUrl('/media/svg/misc/clock.svg')} />
        </div>
        <span className="font-bold text-white bg-primary widget-upcoming-order-cal">
          <FormattedDate
            value={new Date(order?.billingDate)}
            year="numeric"
            month="long"
            day="2-digit"
          />
        </span>
      </div>
      <div className="flex items-center justify-center">
        <ul className="list-disc px-[2rem] py-[1rem]">
          {orders.slice(0, 3).map((order: any) => (
            <li key={order.id} className="py-[1rem] list-none">
              {lineItem?.productTitle}
              <br />
              <span className="font-bold">Lieferinformation:</span>
              <br />
              <FormattedDate
                value={new Date(order?.billingDate)}
                year="numeric"
                month="long"
                day="2-digit"
              />
              ,<span className="ml-1">{order?.shippingAddress?.address1}.</span>
            </li>
          ))}
        </ul>
      </div>
    </Widget>
  );
};
