import axios, { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  fetchCustomerFailure,
  fetchCustomerSuccess,
  updateCustomerFailure,
  updateCustomerRequest,
  updateCustomerSuccess,
} from './actions';
import { FETCH_CUSTOMER_REQUEST, UPDATE_CUSTOMER_REQUEST } from './actionTypes';
import { ICustomer } from './types';

const getCustomer = () => axios.get<ICustomer>(`/api/v1/customers/1`);

const updateCustomer = async (id: number, params: any) => {
  const response = await axios.put<ICustomer>(`/api/v1/customers/${id}`, params);
  return response.data;
};

function* fetchCustomerSaga() {
  try {
    const response: AxiosResponse<ICustomer> = yield call(getCustomer);

    yield put(
      fetchCustomerSuccess({
        customer: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchCustomerFailure({
        error: e.message,
      }),
    );
  }
}

function* updateCustomerSaga(action: ReturnType<typeof updateCustomerRequest>) {
  try {
    const response: AxiosResponse<ICustomer> = yield call(() =>
      updateCustomer(action.payload.id, action.payload.params),
    );

    yield put(
      updateCustomerSuccess({
        customer: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      updateCustomerFailure({
        error: e.message,
      }),
    );
  }
}

export function* customerSaga() {
  yield all([
    takeLatest(FETCH_CUSTOMER_REQUEST, fetchCustomerSaga),
    takeLatest(UPDATE_CUSTOMER_REQUEST, updateCustomerSaga),
  ]);
}
