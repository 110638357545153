import { useDispatch } from 'react-redux';
import { switchSubscriptionRequest, updateSettings } from '../core/actions';
import { ISubscription } from '@modules/subscriptions/core/types';

export const useSwitchSubscription = () => {
  const dispatch = useDispatch();

  const switchDefaultSubscription = (subscription: ISubscription) => {
    // dispatch(
    //   switchSubscriptionRequest({
    //     id: subscription?.id,
    //   }),
    // );
    dispatch(updateSettings({ defaultSubscription: subscription?.id }));
  };

  return {
    switchDefaultSubscription,
  };
};
