import { useIdentityRepository } from 'src/app/hooks/useIdentityRepository';
import { ISubscription } from '../core/types';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getAuthToken } from '../../auth/core/selectors';
import {
  getDefaultSubscriptionSelector,
  getSubscriptionsSelector,
} from '@modules/subscriptions/core/selectors';
import { updateSettings } from '../../settings/core/actions';
import { readToken } from '../../auth/utils/readToken';

export const useSubscriptions = () => {
  const dispatch = useDispatch();

  const repository = useIdentityRepository();
  const identity = repository?.getIdentity();

  const subscriptions = useSelector(getSubscriptionsSelector);
  const defaultSubscription = useSelector(getDefaultSubscriptionSelector);

  const token = useSelector(getAuthToken);

  const getDefaultSubscription = () => {
    return identity?.subscription;
  };

  const setDefaultSubscription = (subscriptions: ISubscription[]) => {
    if (identity?.subscription) return;

    if (!defaultSubscription) {
      const newDefaultSubscription = subscriptions.length ? subscriptions[0] : null;
      dispatch(updateSettings({ defaultSubscription: newDefaultSubscription?.id ?? null }));

      repository?.saveIdentity({
        subscription: newDefaultSubscription,
      });
    }
  };

  const updateDefaultSubscription = () => {
    const decodedToken: any = readToken(token);
    const authSubscription = decodedToken.data.subscription;

    if (authSubscription && authSubscription.id !== defaultSubscription.id) {
      const newDefaultSubscription = subscriptions?.find(
        (sub: ISubscription) => sub.id === authSubscription.id,
      );
      dispatch(updateSettings({ defaultSubscription: newDefaultSubscription }));

      repository?.updateIdentity({
        subscription: newDefaultSubscription,
      });
    }
  };

  return {
    defaultSubscription,
    getDefaultSubscription,
    setDefaultSubscription,
    updateDefaultSubscription,
  };
};
