import { Widget } from '@components/widget/Widget';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';

export const NewDog = () => {
  return (
    <Widget
      header={
        <p>
          <span className="font-bold">Neuer</span> Hund?
        </p>
      }
      footer={<a href="https://futter.hellobello.at/">Zum Konfigurator</a>}
    >
      <div>
        <p className="mb-[2rem] p-[1em]">
          Hast du einen neuen Hund in deinem Zuhause? Möchtest du HelloBello Futter für ihn
          bestellen?
        </p>
        <a
          target="_blank"
          className="uppercase"
          href="https://futter.hellobello.at"
          rel="noreferrer"
        >
          <span className="flex items-center justify-start">
            <KTSVG path={toAbsoluteUrl('/media/svg/pet/new-pet.svg')} className="svg-icon-4hx" />
            <span className="text-xl font-bold text-primary">Neuen Hund hinzufügen</span>
          </span>
        </a>
      </div>
    </Widget>
  );
};
