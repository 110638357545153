import { FC } from 'react';
import { useLang } from './Metronici18n';
import { IntlProvider } from 'react-intl';

import deMessages from './messages/de.json';
import enMessages from './messages/en.json';
import { WithChildren } from '../helpers';

const allMessages = {
  de: deMessages,
  en: enMessages,
};

const I18nProvider: FC<WithChildren> = ({ children }) => {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export { I18nProvider };
