import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Aside } from './components/aside/Aside';
import { Footer } from './components/footer/Footer';
import { HeaderWrapper } from './components/header/HeaderWrapper';
import { Content } from './components/Content';
import { PageDataProvider } from './core';
import { useLocation } from 'react-router-dom';
import { MenuComponent } from '../assets/ts/components';
import { WithChildren } from '../helpers';
import {
  themeModeSwitchHelper,
  useThemeMode,
} from '../partials/layout/theme-mode/ThemeModeProvider';
import { fetchSubscriptionsRequest } from '../../app/modules/subscriptions/core/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getSubscriptionsSelector } from 'src/app/modules/subscriptions/core/selectors';
import { useSubscriptions } from 'src/app/modules/subscriptions/hooks/useSubscriptions';
import { getAuthToken } from 'src/app/modules/auth/core/selectors';
import { fetchOrderHistoryRequest } from 'src/app/modules/orders/core/actions';
import { fetchOrderScheduleRequest } from '@modules/order-schedule/core/actions';
import { getCustomerSelector } from 'src/app/modules/customer/core/selectors';
import { fetchCustomerRequest } from 'src/app/modules/customer/core/actions';
import { fetchAddressesRequest } from 'src/app/modules/addresses/core/actions';
import { getAddressesSelector } from 'src/app/modules/addresses/core/selectors';
import { fetchPaymentMethodsRequest } from 'src/app/modules/payment/core/actions';
import { getPaymentMethodsSelector } from 'src/app/modules/payment/core/selectors';

import { getPendingSelector as getAuthPendingSelector } from '@modules/settings/core/selectors';
import { getPendingSelector as getOrdersPendingSelector } from '@modules/orders/core/selectors';
import { getPendingSelector as getSubscriptionsPendingSelector } from '@modules/subscriptions/core/selectors';
import { getPendingSelector as getAddressesPendingSelector } from '@modules/addresses/core/selectors';
import { getPendingSelector as getSettingsPendingSelector } from '@modules/settings/core/selectors';
import { Loading } from '@components/index';
import { GeorgiaChatWidget } from '@modules/georgia-chat-widget/GeorgiaChatWidget';

const MasterLayout: FC<WithChildren> = ({ children }) => {
  const dispatch = useDispatch();

  const { mode } = useThemeMode();
  const location = useLocation();

  const customer = useSelector(getCustomerSelector);
  const addresses = useSelector(getAddressesSelector);
  const paymentMethods = useSelector(getPaymentMethodsSelector);
  const subscriptions = useSelector(getSubscriptionsSelector);

  const authToken = useSelector(getAuthToken);

  const { defaultSubscription, setDefaultSubscription, updateDefaultSubscription } =
    useSubscriptions();

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  useEffect(() => {
    themeModeSwitchHelper(mode);
  }, [mode]);

  useEffect(() => {
    if (!subscriptions?.length) dispatch(fetchSubscriptionsRequest());
  }, []);

  useEffect(() => {
    if (!paymentMethods?.length) dispatch(fetchPaymentMethodsRequest());
  }, [dispatch, paymentMethods]);

  useEffect(() => {
    if (!customer) dispatch(fetchCustomerRequest());
  }, [dispatch, customer]);

  useEffect(() => {
    if (!addresses?.length) dispatch(fetchAddressesRequest());
  }, [dispatch, addresses]);

  useEffect(() => {
    if (!defaultSubscription) setDefaultSubscription(subscriptions);
  }, [defaultSubscription, subscriptions, setDefaultSubscription]);

  // useEffect(() => {
  //   if (authToken) updateDefaultSubscription();
  // }, [authToken, updateDefaultSubscription]);

  useEffect(() => {
    if (defaultSubscription && subscriptions?.length) {
      dispatch(
        fetchOrderScheduleRequest({
          subscriptionId: defaultSubscription.id,
        }),
      );
      dispatch(fetchOrderHistoryRequest({ subscriptionId: defaultSubscription.id }));
    }
  }, [dispatch, subscriptions, defaultSubscription]);

  const isPendingAuth = useSelector(getAuthPendingSelector);
  const isPendingOrders = useSelector(getOrdersPendingSelector);
  const isPendingSubscriptions = useSelector(getSubscriptionsPendingSelector);
  const isPendingAddresses = useSelector(getAddressesPendingSelector);
  const isPendingSettings = useSelector(getSettingsPendingSelector);

  const isLoading =
    isPendingAuth ||
    isPendingOrders ||
    isPendingSubscriptions ||
    isPendingAddresses ||
    isPendingSettings;

  return (
    <PageDataProvider>
      {isLoading && <Loading />}
      <div className="flex flex-row page">
        <div className="flex flex-col flex-auto wrapper" id="kt_wrapper">
          <HeaderWrapper />

          <div id="kt_content" className="flex flex-col content">
            {/* <Toolbar /> */}
            <div className="container justify-center mx-auto md:flex lg:items-start" id="kt_post">
              <Aside />
              <Content>
                <div className="lg:p-12 main-content px-[1rem]">
                  {customer ? <Outlet /> : <p>loading</p>}
                </div>
                <GeorgiaChatWidget />
                <Footer />
              </Content>
            </div>
          </div>
        </div>
      </div>
    </PageDataProvider>
  );
};

export { MasterLayout };
