import { ReactNode, useState } from 'react';
import { TabDetails } from './tab/TabDetails';
import { TabSummary } from './tab/TabSummary';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';
import { Divider } from '@layout/components/Divider';
import { Button } from '@components/button/Button';

export interface ITab {
  key: string;
  summary: ReactNode;
  details: ReactNode;
}

export type TabsProps = {
  tabs: ITab[];
  button?: ReactNode;
  handleAction?: () => void;
};

const Tabs = ({ tabs, handleAction }: TabsProps) => {
  const [activeTab, setActiveTab] = useState<ITab>(tabs[0]);
  const handleActive = (tab: ITab) => setActiveTab(tab);

  return (
    <div className="my-6 md:flex tabs">
      <nav className="md:shadow-xl rounded-tl-[3rem] rounded-tr-[3rem] md:rounded-tr-none md:rounded-bl-[3rem] py-[3rem] bg-light-100 md:w-1/3">
        {tabs.map((tab: ITab) => (
          <TabSummary
            id={tab.key}
            key={tab.key}
            handleActive={() => handleActive(tab)}
            active={tab.key === activeTab.key}
          >
            {tab.summary}
          </TabSummary>
        ))}

        {handleAction && (
          <>
            <div className="pt-[3rem] mx-[2.5rem]">
              <Divider />
            </div>

            <div className="flex mx-auto py-[2.5rem] justify-center">
              <Button
                variant="simple"
                icon={toAbsoluteUrl('/media/svg/general/plus.svg')}
                iconPosition="before"
                color="primary"
                onClick={handleAction}
              >
                Add New
              </Button>
            </div>
          </>
        )}
      </nav>
      <div className="md:w-2/3">
        <TabDetails active={true}>{activeTab?.details}</TabDetails>
      </div>
    </div>
  );
};

export { Tabs };
