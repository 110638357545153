import clsx from 'clsx';
import { ReactNode } from 'react';
export type TabDetailsProps = {
  children: ReactNode;
  active?: boolean;
};

const TabDetails = ({ children, active = false }: TabDetailsProps) => {
  return (
    <div
      className={clsx(
        'tab-collapse p-[2.5rem] md:p-[0] collapse md:rounded-tr-[3rem] md:rounded-bl-none rounded-bl-[3rem] rounded-br-[3rem] shadow-2xl !h-full',
        {
          show: active,
        },
      )}
    >
      <div className="tab-body md:p-[3rem]">{children}</div>
    </div>
  );
};

export { TabDetails };
